import chill from './chill.jpg'
import jidelna from './jidelna.jpg'
import koutek from './koutek.jpg'
import obejvak from './obejvak.jpg'
import outside from './outside.jpg'
import tmavyObejvak from './tmavy-obejvak.jpg'

export const backgrounds = [
	{ name: 'Tmavý obývák', src: tmavyObejvak, neonTransform: `translate(-5%, -20%) scale(2)` },
	{ name: 'Chill', src: chill, neonTransform: `translate(-5%, -20%) scale(2)` },
	{ name: 'Koutek', src: koutek, neonTransform: `translate(17%, -20%) scale(2.5)` },
	{
		name: 'Jidelna',
		src: jidelna,
		neonTransform: `translate(5%, -15%) perspective(260px) rotateY(-20deg)  scale(2.3)`,
	},
	{ name: 'Obejvák', src: obejvak, neonTransform: `translate(0%, -3%)` },
	{ name: 'Outside', src: outside, neonTransform: `translate(-1%, -36%)` },
]
