import clsx from 'clsx'
import { produce } from 'immer'
import Image from 'next/image'
import React, { useEffect, useMemo, useRef, useState } from 'react'
import { z } from 'zod'
import type { FontResult } from '../../app/data/content/FontFragment'
import type { MaterialResult } from '../../app/data/content/MaterialFragment'
import { Button } from '../../components/Button'
import { Cross } from '../../components/Cross'
import { ModalContent } from '../../components/ModalContent'
import { ParagraphIcon } from '../../components/ParagraphIcon'
import { fixMissingChars } from '../../utils/fixMissingCharacters'
import { ModalContext } from '../../utils/ModalContext'
import { useConfiguratorInfoContext } from '../contember/transformers'
import { ColorLabel } from './ColorLabel'
import { charDictionary } from './Configurator'
import styles from './Configurator.module.sass'
import { FontLabel } from './FontLabel'
import { RadioPickerV2 } from './RadioPickerV2'
import { RangeSlider } from './RangeSlider'

export const SvgPreview = ({
	texts,
	x: offsetX,
	y: offsetY,
	onDimensions,
}: {
	texts: { text: string; fontSize: number; fontFamily: string; color: string; outlined: boolean }[]
	x: number
	y: number
	onDimensions: (dimensions: { width: number; height: number }) => void
}) => {
	const svgRef = useRef<SVGGElement | null>(null)
	const textRefs = useRef<Record<number, SVGTextElement | null>>({})
	const [textDimensions, setTextDimensions] = useState<{ width: number; height: number }[]>([])

	useEffect(() => {
		const texts = Object.values(textRefs.current)
		if (svgRef.current && texts.length === texts.length) {
			const dimensions = texts.map((textRef) => {
				if (textRef) {
					const bbox = textRef.getBBox()
					return { width: bbox.width, height: bbox.height }
				}
				return { width: 0, height: 0 }
			})
			setTextDimensions(dimensions)
		}
	}, [texts])

	const totalWidth = textDimensions.reduce((sum, dim) => sum + dim.width, 0)
	const maxHeight = Math.max(...textDimensions.map((dim) => dim.height), 0)

	const roundedWidth = Math.round(totalWidth)
	const roundedHeight = Math.round(maxHeight)

	useEffect(() => {
		onDimensions({ width: roundedWidth, height: roundedHeight })
	}, [roundedWidth, roundedHeight, onDimensions])

	return (
		<g ref={svgRef} width={totalWidth * 2} height={maxHeight * 2} cx={offsetX} cy={offsetY}>
			{texts.map((text, index) => {
				const x = textDimensions.slice(0, index).reduce((sum, dim) => sum + dim.width, 0)

				return (
					<g key={index}>
						<text
							ref={(el) => (textRefs.current[index] = el)}
							x={x + offsetX}
							y={maxHeight / 2 + offsetY}
							style={{
								overflow: 'visible',
								fontFamily: text.fontFamily,
								fontSize: text.fontSize,
								fill: text.outlined ? 'transparent' : text.color,
								stroke: text.outlined ? text.color : 'transparent',
								strokeWidth: text.outlined ? 0.5 + text.fontSize * 0.02 : 0,
								filter: `drop-shadow(0px 0px ${text.fontSize * 0.15}px ${text.color})`,
								whiteSpace: 'nowrap',
							}}
							dominantBaseline="middle">
							{text.text}
						</text>
					</g>
				)
			})}
		</g>
	)
}

const UTF_NBSP = '\u00A0'

type ConfiguratorV2Word = {
	id: string
	text: string
	font: FontResult
	material: MaterialResult
	fontSize: number
}

export type ConfiguratorV2Line = {
	id: string
	words: ConfiguratorV2Word[]
}

export const ConfiguratorV2State = z.object({
	scale: z.number(),
	align: z.enum(['left', 'center', 'right']),
	fontSize: z.number(),
	padding: z.number(),
	lines: z.array(
		z.object({
			id: z.string().uuid(),
			words: z.array(
				z.object({
					id: z.string().uuid(),
					text: z.string(),
					font: z.unknown(),
					material: z.unknown(),
					fontSize: z.number(),
				})
			),
		})
	),
})

type ConfiguratorV2State = z.infer<typeof ConfiguratorV2State>

export const useConfiguratorV2 = (initialState?: ConfiguratorV2State) => {
	const info = useConfiguratorInfoContext()

	const [scale, setScale] = useState(initialState?.scale ?? 1)
	const [align, setAlign] = useState<'left' | 'center' | 'right'>(initialState?.align ?? 'left')

	const firstFont = Object.values(info.fonts)[0]
	const firstColor = Object.values(info.lightMaterials)[0]
	const defaultFontSize = 15

	console.log({ info })

	const [lines, setLines] = useState<ConfiguratorV2Line[]>(
		(initialState?.lines as any) ??
			(() => [
				{
					id: crypto.randomUUID(),
					words: [
						{
							id: crypto.randomUUID(),
							text: 'Ahoj',
							font: firstFont,
							material: firstColor,
							fontSize: defaultFontSize,
						},
					],
				},
			])
	)

	console.log(lines)

	const linesLength = lines.length

	const [lineGap, setLineGap] = useState(0)

	const [dimensions, setDimensions] = useState<{ width: number; height: number }[]>([])

	const { placements, maxWidth, maxHeight } = useMemo(() => {
		let latestY = 0
		let maxWidth = 0

		const placements = dimensions.slice(0, linesLength).map((dim) => {
			const y = latestY
			latestY += dim.height + lineGap
			maxWidth = Math.max(maxWidth, dim.width)
			return { x: 0, y, ...dim }
		})

		const maxHeight = latestY - lineGap

		return { placements, maxWidth, maxHeight }
	}, [dimensions, linesLength, lineGap])

	const fonts = Object.values(info.fonts)

	const [fontSize, setFontSize] = useState(initialState?.fontSize ?? 20)

	const [padding, setPadding] = useState(initialState?.padding ?? 80)

	const dimensionsOffset = 10

	const dimensionsColor = 'black'

	const dimensionsFontSize = 8
	const dimensionsStrokeWidth = 0.5

	return {
		lines,
		setLines,
		dimensions,
		maxWidth,
		maxHeight,
		placements,
		fontSize,
		setFontSize,
		padding,
		setPadding,
		fonts,
		setDimensions,
		dimensionsOffset,
		dimensionsColor,
		dimensionsStrokeWidth,
		dimensionsFontSize,
		scale,
		setScale,
		setLineGap,
		lineGap,
		align,
		setAlign,
	}
}

export type ConfiguratorV2Store = ReturnType<typeof useConfiguratorV2>

export const ConfiguratorV2 = ({
	//showGrid = false,
	store,
}: {
	showGrid?: boolean
	store: ConfiguratorV2Store
}) => {
	const {
		lines,
		maxWidth,
		maxHeight,
		placements,
		padding,
		dimensions,
		setDimensions,
		dimensionsOffset,
		dimensionsColor,
		dimensionsStrokeWidth,
		dimensionsFontSize,
		scale,
		align,
	} = store

	const alignPosition = align === 'left' ? 0 : align === 'center' ? 0.5 : 1

	return (
		<div className={styles.PreviewSvg}>
			<div
				style={{
					width: '100%',
					height: '100%',
					display: 'flex',
					justifyContent: 'center',
					alignItems: 'center',
					margin: 'auto',
				}}>
				<svg
					width={maxWidth + padding * 2}
					height={maxHeight + padding * 2}
					viewBox={`0 0 ${maxWidth + padding * 2} ${maxHeight + padding * 2}`}
					style={{
						margin: 'auto',
						pointerEvents: 'none',
						width: `${(100 * (maxWidth + padding * 2)) / 800}%`,
						height: `${(100 * (maxHeight + padding * 2)) / 600}%`,
					}}>
					{lines.map((line, t) => {
						return (
							<SvgPreview
								key={t}
								x={
									alignPosition * (maxWidth - (dimensions[t]?.width ?? 0)) +
									padding +
									(placements[t]?.x ?? 0)
								}
								y={padding + placements[t]?.y ?? 0}
								onDimensions={(dimensions) =>
									setDimensions((old) => {
										if (
											old[t]?.width === dimensions.width &&
											old[t]?.height === dimensions.height
										) {
											return old
										}
										const cloned = [...old]
										cloned[t] = dimensions
										return cloned
									})
								}
								texts={line.words.map((word, i) => {
									const adjustedFontSize = word.fontSize * (100 / (word.font.baseFontSize ?? 0))

									const text = (
										fixMissingChars(
											i > 0 ? ` ${word.text}` : word.text,
											charDictionary(word.font.id)
										) ?? ''
									).replace(/\s+/g, UTF_NBSP)

									return {
										text,
										fontFamily: JSON.stringify(word.font.id),
										color: word.material.colorHex ?? 'black',
										fontSize: adjustedFontSize * scale,
										outlined: word.font.toBeOutlined ?? false,
									}
								})}
							/>
						)
					})}
					{!!(maxWidth && maxHeight) && (
						<>
							<line
								x1={padding}
								y1={padding - dimensionsOffset}
								x2={maxWidth + padding}
								y2={padding - dimensionsOffset}
								stroke={dimensionsColor}
								strokeWidth={dimensionsStrokeWidth}
							/>
							<line
								x1={padding - dimensionsOffset}
								y1={padding}
								x2={padding - dimensionsOffset}
								y2={maxHeight + padding}
								stroke={dimensionsColor}
								strokeWidth={dimensionsStrokeWidth}
							/>
							<text
								x={padding + maxWidth / 2}
								y={padding - dimensionsOffset * 2}
								fontFamily="monospace"
								fontSize={dimensionsFontSize}
								textAnchor="middle"
								fill={dimensionsColor}
								dominantBaseline="middle">
								{maxWidth} cm
							</text>
							<text
								x={padding - dimensionsOffset * 2}
								y={padding + maxHeight / 2}
								fontFamily="monospace"
								fontSize={dimensionsFontSize}
								textAnchor="end"
								fill={dimensionsColor}
								dominantBaseline="middle">
								{maxHeight} cm
							</text>
						</>
					)}
				</svg>
			</div>
		</div>
	)
}

export const ConfiguratorV2Controls = ({ store }: { store: ConfiguratorV2Store }) => {
	const info = useConfiguratorInfoContext()

	const options = useMemo(() => {
		return {
			fonts: Object.values(info.fonts),
			lightMaterials: Object.values(info.lightMaterials),
		}
	}, [info])
	const { lines, setLines } = store

	const firstFont = options.fonts[0]
	const firstColor = options.lightMaterials[0]

	const [activeWordId, setActiveWordId] = useState<string | null>(null)

	const activeWord = useMemo(() => {
		return lines.flatMap((l) => l.words).find((w) => w.id === activeWordId)
	}, [lines, activeWordId])

	const modalContext = React.useContext(ModalContext)

	return (
		<div>
			<div>
				{lines.map((line) => (
					<div key={line.id} className={styles.Line}>
						{line.words.map((word) => (
							<div key={word.id} className={styles.Word}>
								<input
									className={clsx(styles.TextArea, activeWordId === word.id && styles.ActiveWord)}
									value={word.text}
									onFocus={() => setActiveWordId(word.id)}
									onChange={(e) => {
										setActiveWordId(word.id)
										setLines(
											produce((old) => {
												const w = old
													.find(({ id }) => id === line.id)
													?.words?.find(({ id }) => id === word.id)

												if (w) {
													w.text = e.target.value
												}
											})
										)
									}}
								/>
								<button
									className={styles.RemoveWord}
									type="button"
									aria-label="Odstranit slovo"
									title="Odstranit slovo"
									onClick={() => {
										setLines((old) => {
											return old
												.map((line) => ({
													...line,
													words: line.words.filter((w) => w.id !== word.id),
												}))
												.filter((line) => line.words.length)
										})
									}}>
									<Cross />
								</button>
							</div>
						))}
						<button
							className={styles.AddButton}
							type="button"
							onClick={() => {
								setLines(
									produce((old) => {
										old
											.find(({ id }) => id === line.id)
											?.words.push({
												id: crypto.randomUUID(),
												text: '',
												font: firstFont,
												material: firstColor,
												fontSize: 15,
											})
									})
								)
							}}>
							<span style={{ transform: 'rotate(-45deg)' }}>
								<Cross />
							</span>
							Přidat slovo
						</button>
						{/* <button
							type="button"
							onClick={() => {
								setLines((old) => old.filter((l) => l.id !== line.id))
							}}>
							Odstranit řádek
						</button> */}
					</div>
				))}
				<button
					className={clsx(styles.AddButton, styles.AddLine)}
					type="button"
					onClick={() => {
						setLines((old) => [
							...old,
							{
								id: crypto.randomUUID(),
								words: [
									{
										id: crypto.randomUUID(),
										text: '',
										font:
											lines[lines.length - 1] &&
											lines[lines.length - 1].words[lines[lines.length - 1].words.length - 1].font
												? lines[lines.length - 1].words[lines[lines.length - 1].words.length - 1]
														.font
												: firstFont,
										material: firstColor,
										fontSize: 15,
									},
								],
							},
						])
					}}>
					<span>
						<ParagraphIcon />
					</span>
					Přidat řádek
				</button>
			</div>
			{activeWord && (
				<div className={styles.WordEdit}>
					<div>
						<div>
							<RadioPickerV2
								title="Písmo:"
								value={activeWord.font.id}
								onChange={(value) =>
									setLines(
										produce((old) => {
											const w = old.flatMap((l) => l.words).find((w) => w.id === activeWordId)
											const font = options.fonts.find((f) => f.id === value)
											if (w && font) {
												w.font = font
											}
										})
									)
								}
								options={options.fonts.map((font) => {
									return {
										value: font.id,
										label: (
											<FontLabel
												fontId={font.id}
												baseFontSize={font.baseFontSize ?? 0}
												content={'Ab'}
												checked={activeWord.font.id === font.id}
											/>
										),
									}
								})}
								name="font"
								label="Písmo"
							/>
						</div>
					</div>
					<div>
						<RadioPickerV2
							title="Barva:"
							value={activeWord.material.id}
							onChange={(value) =>
								setLines(
									produce((old) => {
										const w = old.flatMap((l) => l.words).find((w) => w.id === activeWordId)
										const material = options.lightMaterials.find((m) => m.id === value)
										if (w && material) {
											w.material = material
										}
									})
								)
							}
							options={options.lightMaterials.map((material) => {
								return {
									value: material.id,
									label: (
										<ColorLabel
											colorHex={material.colorHex}
											colorId={material.id}
											checked={activeWord.material.id === material.id}
										/>
									),
								}
							})}
							name="font"
							label="Barva"
						/>
						<Button
							className="viewTextLink"
							onClick={(e) => {
								e.preventDefault()
								modalContext?.modalContentFunction(
									<ModalContent
										headline="Vzorník našich barev"
										content={
											<Image
												src={
													'https://s3.eu-central-1.amazonaws.com/api.contember.mangoweb.cz/neonhort/89d3401c-98ff-48c7-9b32-efb64100e443.jpeg'
												}
												alt="vzorník barev"
												width={500}
												height={500}
											/>
										}
									/>
								)
								modalContext?.modalOpenFunction(true)
							}}>
							Podívej se na vzorník
						</Button>
						<br></br>
						<br></br>
					</div>
					<div>
						<RangeSlider
							min={15}
							max={45}
							name="fontSize"
							title={`Velikost písma: ${activeWord.fontSize}`}
							onChange={(value) => {
								const fontSize = Number(value)
								setLines(
									produce((old) => {
										const w = old.flatMap((l) => l.words).find((w) => w.id === activeWordId)
										if (w) {
											w.fontSize = fontSize
										}
									})
								)
							}}
							label={`Velikost písma: ${activeWord.fontSize}`}
							value={activeWord.fontSize}
						/>
					</div>
				</div>
			)}
		</div>
	)
}
