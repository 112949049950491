import clsx from 'clsx'
import s from './FontLabel.module.sass'

export function FontLabel(props: {
	fontId: string
	baseFontSize: number
	content?: string
	checked: boolean
}) {
	return (
		<div className={clsx(s.FontLabel, props.checked && s.Checked)}>
			<span
				style={{
					fontFamily: `'${props.fontId}'`,
					fontSize: `${1 * (100 / props.baseFontSize)}em`,
				}}
				className={s.FontLabelInner}>
				{props.content}
			</span>
		</div>
	)
}
