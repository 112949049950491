import type { FontResult } from '../app/data/content/FontFragment'

export function Fonts(props: { fonts: Array<FontResult> }) {
	return (
		<style>
			{props.fonts.map((font) => {
				return `@font-face {
							font-family: '${font.id}';
							src: ${font.sourceFiles?.items.map(
								(file) =>
									`url('${file.file?.url}') ${
										!file.file?.type?.includes('woff') ? "format('woff2')" : ''
									}`
							)};
						}`
			})}
		</style>
	)
}
