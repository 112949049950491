import type { ReactNode } from 'react'
import styles from '../app/styles/ModalContent.module.sass'

export function ModalContent(props: { headline?: string; content: ReactNode }) {
	return (
		<div className={styles.wrapper}>
			<h2 className={styles.headline}>{props.headline}</h2>
			<div className={styles.content}>{props.content}</div>
		</div>
	)
}
