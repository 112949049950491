import type { ConfiguratorV2Line } from '../neon/configurator/SvgPreview'

export function getConfiguratorPriceV2(lines: ConfiguratorV2Line[], canvasSizePriceKoef: number) {
	const baseFontSize = 15

	let priceForLetters = 0

	const letters = lines.flatMap((line) =>
		line.words.flatMap((word) =>
			word.text
				.replace(/\s+/g, '')
				.split('')
				.map((letter) => ({
					letter,
					word,
					line,
				}))
		)
	)

	letters.forEach((letter) => {
		const font = letter.word.font
		const pricePerLetter =
			font && font.localesByLocale?.characterPrice ? font.localesByLocale?.characterPrice : 100
		const fontSize = letter.word.fontSize
		const fontSizeKoef = fontSize / baseFontSize
		priceForLetters += pricePerLetter * fontSizeKoef
	})

	const unscaledTotal = priceForLetters
	const preTotal = canvasSizePriceKoef * unscaledTotal
	const total = preTotal < 1900 ? 1900 : Math.round(preTotal)

	return { unscaledTotal, total, canvasSizePriceKoef, letters }
}
