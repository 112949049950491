import React from 'react'

export function ParagraphIcon() {
	return (
		<svg
			id="Vrstva_2"
			data-name="Vrstva 2"
			xmlns="http://www.w3.org/2000/svg"
			viewBox="0 0 214.36 144.75"
			color="currentColor"
			fill="currentColor">
			<g id="Vrstva_2-2" data-name="Vrstva 2">
				<path d="M205.86,17H8.5C3.81,17,0,13.19,0,8.5S3.81,0,8.5,0h197.36c4.69,0,8.5,3.81,8.5,8.5s-3.81,8.5-8.5,8.5Z" />
				<path d="M171.98,81.38H8.5c-4.69,0-8.5-3.81-8.5-8.5s3.81-8.5,8.5-8.5h163.48c4.69,0,8.5,3.81,8.5,8.5s-3.81,8.5-8.5,8.5Z" />
				<path d="M110.07,144.75H8.5c-4.69,0-8.5-3.81-8.5-8.5s3.81-8.5,8.5-8.5h101.57c4.69,0,8.5,3.81,8.5,8.5s-3.81,8.5-8.5,8.5Z" />
			</g>
		</svg>
	)
}
