import { useCallback } from 'react'
import s from './RadioPicker.module.sass'

export function RadioPicker<N extends string, V extends string>(props: {
	title?: React.ReactNode
	name: N
	value: V
	label?: string | ((value: V, name: N) => React.ReactNode)
	onChange: (name: N, value: V) => void
	options: V[]
}) {
	const { name, value, onChange } = props
	const handleChange = useCallback<React.ChangeEventHandler<HTMLInputElement>>(
		(e) => {
			onChange(name, e.currentTarget.value as V)
		},
		[name, onChange]
	)

	return (
		<div className={s.RadioPicker}>
			{props.title && <p>{props.title}</p>}
			<div>
				{props.options.map((v) => {
					const l = props.label
						? typeof props.label === 'function'
							? props.label(v, props.name)
							: props.label
						: v

					return (
						<label key={v} className={s.InputWrapper}>
							<input
								type="radio"
								name={name}
								value={v}
								checked={value === v}
								onChange={handleChange}
								className={s.Input}
							/>
							<span className={s.InputLabel}> {l}</span>
						</label>
					)
				})}
			</div>
		</div>
	)
}
