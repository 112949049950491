import clsx from 'clsx'
import type { ReactNode } from 'react'
import s from './BasicLabel.module.sass'

export function BasicLabel(props: {
	valueId: string
	content?: string
	subContent?: string
	checked: boolean
	icon?: ReactNode
}) {
	return (
		<div className={clsx(s.BasicLabel, props.checked && s.Checked)}>
			<span className={s.BasicLabelInner}>
				{props.icon && props.icon}
				{props.content}
			</span>
		</div>
	)
}
