import clsx from 'clsx'
import { ReactNode, useCallback } from 'react'
import s from './RadioPickerV2.module.sass'

export function RadioPickerV2(props: {
	title?: React.ReactNode
	name: string
	value: string | number
	label?: string
	onChange: (value: string) => void
	options: Array<{ value: string | number; label: ReactNode }>
	view?: 'basic'
}) {
	const { name, value, onChange } = props
	const handleChange = useCallback<React.ChangeEventHandler<HTMLInputElement>>(
		(e) => {
			onChange(e.currentTarget.value)
		},
		[onChange]
	)

	return (
		<div className={clsx(s.RadioPicker, props.view === 'basic' && s.ViewBasic)}>
			{props.title && <p>{props.title}</p>}
			<div className={s.RadioPickerInner}>
				{props.options.map((v) => {
					return (
						<label key={v.value} className={s.InputWrapper}>
							<input
								type="radio"
								name={name}
								value={v.value}
								checked={value === v.value}
								onChange={handleChange}
								className={s.Input}
							/>
							<span className={s.InputLabel}>{v.label}</span>
						</label>
					)
				})}
			</div>
		</div>
	)
}
