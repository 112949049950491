import clsx from 'clsx'
import s from './ColorLabel.module.sass'

export function ColorLabel(props: { colorId: string; colorHex?: string; checked: boolean }) {
	return (
		<div className={clsx(s.ColorLabel, props.checked && s.Checked)}>
			<span className={s.ColorLabelInner} style={{ backgroundColor: `${props.colorHex}` }}></span>
		</div>
	)
}
