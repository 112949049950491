export type Dict = {
	bad: string
	good: string
}

export const CZ_CHARS: Dict = {
	bad: 'ěščřžýáíéúůťďňóĚŠČŘŽÝÁÍÉÚŮŤĎŇÓ',
	good: 'escrzyaieuutdnoESCRZYAIEUUTDNO',
}

export function fixMissingChars(str: string | undefined, dict: Dict | false = false) {
	if (!dict) {
		return str
	}
	return (str ?? '').replace(new RegExp(`([${dict.bad}])`, 'g'), (m) => {
		const index = dict.bad.indexOf(m)
		return dict.good[index]
	})
}
