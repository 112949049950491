import React from 'react'
import type { CanvasSizeResult } from '../../app/data/content/CanvasSizeFragment'
import type { FontResult } from '../../app/data/content/FontFragment'
import type { IconResult } from '../../app/data/content/IconFragment'
import type { MaterialResult } from '../../app/data/content/MaterialFragment'
import type { PresetResult } from '../../app/data/content/PresetFragment'
import type {
	ConfiguratorPartType,
	PresetValueResult,
} from '../../app/data/content/PresetValueFragment'
import { PartListItemType } from '../../generated/content'
import type {
	CanvasSizeId,
	ConfiguratorPartEditability,
	ConfiguratorPreset,
	ConfiguratorValuePart,
	ConfiguratorValueV1,
	FontId,
	IconId,
	InputId,
	LightMaterialId,
} from '../types'

export function toCanvasSizeId(value: string | undefined | null) {
	if (!value) {
		throw new Error('Missing value')
	}
	return value as unknown as CanvasSizeId
}

export function toInputId(value: string | undefined | null) {
	if (!value) {
		throw new Error('Missing value')
	}
	return value as unknown as InputId
}

export function toLightMaterialId(value: string | undefined | null) {
	if (!value) {
		throw new Error('Missing value')
	}
	return value as unknown as LightMaterialId
}

export function toIconId(value: string | undefined | null) {
	if (!value) {
		throw new Error('Missing value')
	}
	return value as unknown as IconId
}

export function toFontId(value: string | undefined | null) {
	if (!value) {
		throw new Error('Missing value')
	}
	return value as unknown as FontId
}

export function toPart(part: ConfiguratorPartType): ConfiguratorValuePart {
	const base: Pick<ConfiguratorValuePart, 'offset' | 'lightMaterial' | 'id' | 'layerId'> = {
		id: part.id,
		offset: part.offset,
		lightMaterial: part.material?.id ? toLightMaterialId(part.material.id) : undefined,
		layerId: part.layerId ?? '',
	}

	switch (part.type) {
		case PartListItemType.icon:
			return {
				...base,
				...(part.editable
					? {
							providers: {
								['lightMaterial']: toInputId(`lightMaterial/${part.id}`),
								['icon']: toInputId(`icon/${part.id}`),
							},
					  }
					: {}),
				icon: toIconId(part.icon?.id),
			}
		case PartListItemType.text:
			return {
				...base,
				...(part.editable
					? {
							providers: {
								['lightMaterial']: toInputId(`lightMaterial/${part.id}`),
								['text']: toInputId(`text/${part.id}`),
								['font']: toInputId(`font/${part.id}`),
							},
					  }
					: {}),
				text: part.text ?? '',
				align: part.textAlign ?? undefined,
			}
		case PartListItemType.path:
			return {
				...base,
				...(part.editable
					? {
							providers: {
								['lightMaterial']: toInputId(`lightMaterial/${part.id}`),
							},
					  }
					: {}),
				path: part.path ?? '',
				pathPrice: part.pathPrice ?? 100,
			}
	}
	throw new Error(`Unknown ConfiguratorPartType '${part.type}'`)
}

export function toPresetValue(value: PresetValueResult): ConfiguratorValueV1 {
	const canvasSize = toCanvasSizeId(value.canvasSize?.id)
	const lightMaterial = toLightMaterialId(value.material?.id)
	const font = toFontId(value.font?.id)

	const parts = (value.parts?.items ?? []).map((part) => toPart(part))

	const inputValues: Record<string, string> = {}

	parts.forEach((part) => {
		if ('text' in part) {
			inputValues[`text/${part.id}`] = part.text
		}
		if ('lightMaterial' in part && part.lightMaterial) {
			inputValues[`lightMaterial/${part.id}`] = part.lightMaterial
		}
	})

	return {
		version: '1',
		presetValueId: value.id,
		canvasSize,
		lightMaterial,
		font,
		parts,
		inputValues,
	}
}

export function toPreset(preset: PresetResult): Omit<ConfiguratorPreset, 'inputs'> {
	return {
		lightMaterials: preset.materials.map((item) => toLightMaterialId(item.id)),
		canvasSizes: preset.canvasSizes.map((item) => toCanvasSizeId(item.id)),
		icons: preset.icons.map((item) => toIconId(item.id)),
		fonts: preset.fonts.map((item) => toFontId(item.id)),
	}
}

export function toInputs(value: PresetValueResult) {
	const inputs: ConfiguratorPartEditability[] = []

	const uniqueInputs: Record<string, boolean> = {}

	for (const item of value.parts?.items ?? []) {
		if (item.colorEditable) {
			const key = toInputId(`lightMaterial/${item.id}`)
			if (!uniqueInputs[key]) {
				inputs.push({
					inputId: key,
					input: 'lightMaterial',
					initialValue: item.material?.id ?? null,
				})
				uniqueInputs[key] = true
			}
		}
		if (item.editable) {
			switch (item.type) {
				case PartListItemType.text: {
					const key = toInputId(`text/${item.id}`)
					if (!uniqueInputs[key]) {
						inputs.push({ inputId: key, input: 'text', initialValue: item.text ?? null })
						uniqueInputs[key] = true
					}
					break
				}
				case PartListItemType.icon: {
					const key = toInputId(`icon/${item.id}`)
					if (!uniqueInputs[key]) {
						inputs.push({ inputId: key, input: 'icon', initialValue: item.icon?.id ?? null })
						uniqueInputs[key] = true
					}
					break
				}
			}
		}
	}

	return inputs
}

export type ConfiguratorInfoContextValue = {
	canvasSizes: Record<CanvasSizeId, CanvasSizeResult>
	fonts: Record<FontId, FontResult>
	icons: Record<IconId, IconResult>
	lightMaterials: Record<LightMaterialId, MaterialResult>
}

export const ConfiguratorInfoContext = React.createContext<null | ConfiguratorInfoContextValue>(
	null
)

export function useConfiguratorInfoContext() {
	const context = React.useContext(ConfiguratorInfoContext)

	if (!context) {
		throw new Error('Missing ConfiguratorInfoContext')
	}

	return context
}

export function useConfiguratorInfo<
	T extends keyof ConfiguratorInfoContextValue,
	Id extends keyof ConfiguratorInfoContextValue[T]
>(type: T, id: Id) {
	const context = useConfiguratorInfoContext()

	return context[type][id]
}

export function toConfiguratorInfoContext(preset: PresetResult) {
	const value: ConfiguratorInfoContextValue = {
		canvasSizes: Object.fromEntries(
			(preset.canvasSizes ?? []).map((item) => [toCanvasSizeId(item.id), item] as const)
		),
		fonts: Object.fromEntries(
			(preset.fonts ?? []).map((item) => [toFontId(item.id), item] as const)
		),
		icons: Object.fromEntries(
			(preset.icons ?? []).map((item) => [toIconId(item.id), item] as const)
		),
		lightMaterials: Object.fromEntries(
			(preset.materials ?? []).map((item) => [toLightMaterialId(item.id), item] as const)
		),
	}

	return value
}
