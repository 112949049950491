import { hexToHsluv, hsluvToHex, hsluvToRgb } from 'hsluv'
import Image from 'next/image'
import React, { Fragment, useCallback, useEffect, useMemo, useState } from 'react'
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { useShoppingCart } from 'use-shopping-cart'
import type { Product } from 'use-shopping-cart/core'
import { v4 as uuidv4 } from 'uuid'
import { Button } from '../../components/Button'
import { ModalContent } from '../../components/ModalContent'
import { fetchPostJSON } from '../../utils/api-helpers'
import { CZ_CHARS, fixMissingChars } from '../../utils/fixMissingCharacters'
import { ModalContext } from '../../utils/ModalContext'
import { ShoppingContext } from '../../utils/ShoppingContext'
import {
	ConfiguratorInfoContext,
	ConfiguratorInfoContextValue,
	useConfiguratorInfoContext,
} from '../contember/transformers'
import type {
	CanvasSizeId,
	ConfiguratorInputType,
	ConfiguratorPartEditability,
	ConfiguratorPreset,
	ConfiguratorValuePart,
	ConfiguratorValueV1,
	InputId,
} from '../types'
import { backgrounds } from './backgrounds'
import { BasicLabel } from './BasicLabel'
import s from './Configurator.module.sass'
import { RadioPicker } from './RadioPicker'
import { RadioPickerV2 } from './RadioPickerV2'
import { RangeSlider } from './RangeSlider'
import { ConfiguratorV2, ConfiguratorV2Controls, useConfiguratorV2 } from './SvgPreview'
import type { ConfiguratorHandler } from './useConfiguratorHandler'
import { useConfiguratorPriceInfo } from './useConfiguratorPriceInfo'

export type ConfiguratorProps = {
	preset: ConfiguratorPreset
	handler: ConfiguratorHandler
}

export const createStyle = (color: string) => {
	const ref = hexToHsluv(color)
	const fill = hsluvToHex([ref[0], ref[1], ref[2] + Math.min(10, (100 - ref[2]) / 4)])
	return {
		color: `${fill}`,
		textShadow: `0 0 .3em rgba(${hsluvToRgb(ref)
			.map((x) => x * 255)
			.join(',')}, .6), 0 0 .1em rgba(${hsluvToRgb(ref)
			.map((x) => x * 255)
			.join(',')}, .4)`,
	}
}

export const charDictionary = (fontId: string) => {
	// @TODO: načítat z info, až to bude v contemberu
	switch (fontId) {
		case '5d4af203-f2e1-4b04-a352-0d9a94995d93':
			return CZ_CHARS
		case '3ff5e436-effb-4673-9eb9-b26692258459':
			return CZ_CHARS
		case '7d2f7fb7-7af6-4dc1-9e76-8183d4c4930a':
			return CZ_CHARS
	}
	return false
}

function ConfiguratorPartSvgRenderer<Index extends number>(props: {
	part: ConfiguratorValuePart
	value: ConfiguratorValueV1
	index: Index
	refresh: number
	offsets: { width: number; offsets: ({ x: number; y: number } | null)[] }
	onBounds: (bounds: DOMRect | null, index: Index) => void
}) {
	const { part, onBounds, index, refresh } = props

	const partId = part.id

	const inputValues = {
		text: props.value.inputValues[`text/${partId}`],
		lightMaterial: props.value.inputValues[`lightMaterial/${partId}`],
		font: props.value.inputValues[`font/${partId}`],
	}

	const [el, setEl] = useState<SVGGElement | null>(null)

	const info = useConfiguratorInfoContext()

	const fontId = props.value.font

	const fontInfo = info.fonts[fontId]

	useEffect(() => {
		let t: null | NodeJS.Timeout = null
		let i = 6

		const next = () => {
			onBounds(el ? el.getBBox() : null, index)
			t = setTimeout(() => {
				if (i-- > 0) {
					next()
				}
			}, 200 * (6 - i))
		}

		next()

		return () => {
			if (t) {
				clearTimeout(t)
			}
			i = 0
		}
	}, [el, onBounds, index, refresh, fontId, inputValues.text])

	const lightMaterial = inputValues.lightMaterial || props.value.lightMaterial

	const partColor = info.lightMaterials[lightMaterial].colorHex

	const fontScale = 100 / (fontInfo?.baseFontSize || 100)

	const filter = `drop-shadow(0px 0px 1px ${partColor})`

	const style = {
		color: partColor,
		fontSize: 12,
		filter,
		stroke: partColor,
	}

	const currentOffset = props.offsets.offsets[props.index] ?? null
	const transform = currentOffset ? `translate(${currentOffset.x}, ${currentOffset.y})` : undefined

	const centerProps = {
		x: '50%',
		y: '50%',
	}

	const textProps = {
		dominantBaseline: 'middle',
		...centerProps,
		style: {
			fontFamily: `'${fontId}'`,
			filter,
			fontSize: `${1 * fontScale}em`,
			fill: fontInfo.toBeOutlined ? 'transparent' : partColor,
			stroke: fontInfo.toBeOutlined ? partColor : 'none',
			strokeWidth: fontInfo.toBeOutlined ? '.7px' : 'none',
		},
	}

	let content: React.ReactNode | null = null
	if ('text' in part) {
		content = (
			<text {...textProps}>{fixMissingChars(inputValues.text, charDictionary(fontId))}</text>
		)
	} else if ('icon' in part) {
		const iconPaths = info.icons[part.icon].paths?.split('\n')
		if (!iconPaths) {
			throw new Error(`Invalid icon ${part.icon}`)
		}
		content = iconPaths.map((path, index) => (
			<svg key={index} {...centerProps} cx="-100">
				<path
					style={{
						...style,
						stroke: partColor,
						fill: 'transparent',
						strokeWidth: '.08em',
						filter: 'contrast',
					}}
					d={path}
					stroke="black"
				/>
			</svg>
		))
	} else if ('path' in part) {
		content = (
			<svg {...centerProps} cx="-100">
				<path
					style={{
						...style,
						stroke: style.color,
						fill: 'transparent',
						strokeWidth: '.08em',
					}}
					d={part.path}
					stroke="black"
				/>
			</svg>
		)
	} else {
		throw new Error('Invalid state')
	}

	return (
		<>
			<g transform={transform} ref={setEl}>
				{content}
			</g>
		</>
	)
}

export function Configurator() {
	const { addItem } = useShoppingCart()
	const shoppingContext = React.useContext(ShoppingContext)
	const configuratorContext = React.useContext(ConfiguratorInfoContext)

	const modalContext = React.useContext(ModalContext)

	const [bgIndex, setBgIndex] = useState(0)

	const bg = backgrounds[bgIndex % backgrounds.length]

	const preset = configuratorContext

	const date = new Date()
	const deliveryDate = new Date(date.getTime() + 14 * 86400000)

	//const canvasSizes = preset.canvasSizes.map((item) => info.canvasSizes[item])

	const getBackgroundLabel = (value: string) => {
		return (
			<span className={s.BackgroundLabel}>
				{
					// eslint-disable-next-line @next/next/no-img-element
					<img
						sizes="(max-width: 736px) 20vw, 10vw"
						src={backgrounds[parseInt(value)].src.src}
						alt="room"
					/>
				}
			</span>
		)
	}

	const handleSubmit = async () => {
		//Set product name
		//const productNameReset = false
		let productName = 'Tvůj neon:'
		configuratorV2Store.lines.forEach((line) => {
			if (line.words && line.words[0].text) {
				line.words.forEach((word) => {
					productName += ` ${word.text}`
				})
			}
		})

		//Create SubmittedConfigurator
		const response = await fetchPostJSON('/api/configurator_submit', {
			configurator_v2_data: JSON.stringify(configuratorV2Store),
			preset_data: JSON.stringify(preset),
			name: productName,
			price: price.total,
		})

		if (response.statusCode === 500) {
			console.error(response.message)
			return
		}

		console.log('submited configurator', response)

		const submittedConfiguratorId = response

		//Set other product data
		const product: Product = {
			name: productName,
			id: uuidv4(), //random uuid
			price: price.total,
			currency: 'CZK',
			image: 'https://www.neonhort.cz/tvujneon.jpg',
		}

		addItem(product, {
			product_metadata: {
				submitted_configurator_id: submittedConfiguratorId,
			},
		})
		shoppingContext?.cartOpenFunction(true) //open cart
	}

	const configuratorV2Store = useConfiguratorV2()

	const price = useConfiguratorPriceInfo(configuratorV2Store.lines, configuratorV2Store.scale)

	return (
		<form className={s.Configurator}>
			{/* <Dump data={info} /> */}
			<div className={s.Layout}>
				<div className={s.Controls}>
					<ConfiguratorV2Controls store={configuratorV2Store} />

					{/* {preset.inputs?.map((input) => (
						<ConfiguratorPartEditor
							key={input.inputId}
							input={input}
							handler={handler}
							preset={preset}
							info={info}
						/>
					))} */}

					<RangeSlider
						min={-10}
						max={10}
						name="lineGap"
						title={`Řádkování: ${configuratorV2Store.lineGap}`}
						onChange={(value) => {
							const lineGap = Number(value)
							configuratorV2Store.setLineGap(lineGap)
						}}
						label={`Řádkování: ${configuratorV2Store.lineGap}`}
						value={configuratorV2Store.lineGap}
					/>

					<RadioPickerV2
						title="Velikost:"
						value={configuratorV2Store.scale}
						view={'basic'}
						options={[
							{
								label: (
									<BasicLabel
										content="Malý"
										valueId={'1'}
										checked={configuratorV2Store.scale === 1}
									/>
								),
								value: 1,
							},
							{
								label: (
									<BasicLabel
										content="Střední"
										valueId={'1.5'}
										checked={configuratorV2Store.scale === 1.5}
									/>
								),
								value: 1.5,
							},
							{
								label: (
									<BasicLabel
										content="Velký"
										valueId={'2'}
										checked={configuratorV2Store.scale === 2}
									/>
								),
								value: 2,
							},
						]}
						onChange={(value) => configuratorV2Store.setScale(Number(value))}
						name="scale"
						label="Velikost"
					/>
					<RadioPickerV2
						title="Zarovnání:"
						value={configuratorV2Store.align}
						view="basic"
						options={[
							{
								label: (
									<BasicLabel
										content="Vlevo"
										valueId={'left'}
										checked={configuratorV2Store.align === 'left'}
									/>
								),
								value: 'left',
							},
							{
								label: (
									<BasicLabel
										content="Na střed"
										valueId={'center'}
										checked={configuratorV2Store.align === 'center'}
									/>
								),
								value: 'center',
							},
							{
								label: (
									<BasicLabel
										content="Vpravo"
										valueId={'right'}
										checked={configuratorV2Store.align === 'right'}
									/>
								),
								value: 'right',
							},
						]}
						// eslint-disable-next-line @typescript-eslint/ban-ts-comment
						//@ts-ignore
						onChange={(value) => configuratorV2Store.setAlign(value)}
						name="align"
						label="Zarovnání"
					/>
					<p
						className={s.Info}
						style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
						Nevíš si rady?{' '}
						<Button
							className="viewTextLink"
							onClick={(e) => {
								e.preventDefault()
								modalContext?.modalContentFunction(
									<ModalContent
										headline="Podívejte se, jak používat náš konfigurátor"
										content={
											// eslint-disable-next-line jsx-a11y/media-has-caption
											<video
												src="https://s3.eu-central-1.amazonaws.com/api.contember.mangoweb.cz/neonhort/09278898-db33-4dae-b87b-39a47abbb63a.mp4"
												controls
												autoPlay></video>
										}
									/>
								)
								modalContext?.modalOpenFunction(true)
							}}>
							Podívej se na video
						</Button>
					</p>
					<div className={s.Actions}>
						<div className={s.Price}>
							<p>Cena:</p> {price.total} Kč
						</div>
						<Button onClick={handleSubmit}>Přidat do košíku</Button>
						<p className={s.Info}>
							<br></br>
							Neon na míru doručíme nejpozději do: {deliveryDate.toLocaleDateString()}
						</p>
					</div>
					{/* <div>
						<Dump data={value} />
					</div> */}
				</div>
				<div className={s.Main}>
					<div className={s.Ratio}>
						<div className={s.Promotion}>-30% s kódem VANOCE</div>
						<div className={s.Background}>
							{backgrounds.map((bg, b) => (
								<span
									className={s.BackgroundImage}
									key={b}
									style={{ opacity: b === bgIndex ? 1 : 0 }}>
									<Image sizes="(max-width: 736px) 70vw, 50vw" src={bg.src} layout="fill" alt="" />
								</span>
							))}
						</div>
						<div className={s.RatioIn}>
							<div className={s.Canvas} style={{ transform: bg.neonTransform }}>
								<div className={s.Center}>
									<div className={s.Preview}>
										<div className={s.PreviewParts}>
											<ConfiguratorV2 store={configuratorV2Store} />
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className={s.BackgroundRadio}>
						<RadioPicker
							title="Pozadí:"
							value={String(bgIndex)}
							options={backgrounds.map((_, i) => String(i))}
							onChange={(_name, value) => setBgIndex(Number(value))}
							name="background"
							label={getBackgroundLabel}
						/>
					</div>
				</div>
			</div>
		</form>
	)
}

function ConfiguratorSvgPreviewLayer(props: {
	layerId: string
	value: ConfiguratorValueV1
	onReady: (layerId: string, bounds: Bounds) => void
}) {
	const { value, onReady, layerId } = props
	const { parts } = value

	const [bounds, setBounds] = useState<{
		v: number
		bounds: Record<string, DOMRect | undefined>
		prevBounds: Record<string, DOMRect | undefined>
	}>({
		v: 1,
		bounds: {},
		prevBounds: {},
	})

	const serialized = JSON.stringify(value.parts)

	useEffect(() => {
		setBounds((old) => ({ v: old.v + 1, bounds: {}, prevBounds: old.bounds }))

		const timeout = setTimeout(() => {
			setBounds((old) => ({ ...old, v: old.v + 1 }))
		}, 100)

		return () => clearTimeout(timeout)
	}, [serialized])

	const ready = Object.values(bounds.bounds).filter((x) => Boolean(x)).length === parts.length

	useEffect(() => {
		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		//@ts-ignore
		onReady(layerId, ready)
	}, [ready, layerId, onReady])

	const currentBounds = ready ? bounds.bounds : bounds.prevBounds

	const offsets = useMemo(() => {
		const offsets: (null | { x: number; y: number })[] = []
		let width = 0
		const partSpace = 5
		const iconCenterOffset = 3

		for (const i in parts) {
			const part = parts[i]
			const partBounds = currentBounds[i]
			if (partBounds) {
				if ('text' in part) {
					offsets.push({ x: width, y: 0 })
				} else if ('icon' in part) {
					// TODO: dočasně se chová jako text
					offsets.push({ x: width, y: -(partBounds.height / 2 + iconCenterOffset) })
				} else {
					offsets.push({ x: width, y: -partBounds.height / 2 })
				}
				width += partBounds.width + partSpace
			} else {
				offsets.push(null)
			}
		}

		return { width, offsets }
	}, [parts, currentBounds])

	const handleBounds = useCallback(
		(bounds: DOMRect | null, index: number) =>
			setBounds((old) => {
				if (bounds === old.bounds[index]) {
					return old
				}
				const update = { ...old, bounds: { ...old.bounds } }
				if (bounds) {
					update.bounds[index] = bounds
				} else {
					delete update.bounds[index]
				}
				return update
			}),
		[]
	)

	return (
		<g transform={`translate(${-offsets.width / 2})`}>
			{parts.map((part, i) => (
				<Fragment key={i}>
					<rect
						width={currentBounds[i]?.width}
						height={currentBounds[i]?.height}
						fill="red"
						opacity={0.4}
						x={currentBounds[i]?.x}
						// eslint-disable-next-line @typescript-eslint/ban-ts-comment
						//@ts-ignore
						y={currentBounds[i]?.y - 40}>
						<text x={0} y={0} textAnchor="middle" dominantBaseline="middle">
							{JSON.stringify(currentBounds[i] ?? '')}
						</text>
					</rect>
					<ConfiguratorPartSvgRenderer
						key={i}
						index={i}
						part={part}
						value={value}
						refresh={bounds.v}
						onBounds={handleBounds}
						offsets={offsets}
					/>
				</Fragment>
			))}
		</g>
	)
}

type Bounds = {
	x: number
	y: number
	width: number
	height: number
}

export function ConfiguratorSvgPreview(props: { value: ConfiguratorValueV1 }) {
	const { value } = props
	const { parts } = value
	const info = useConfiguratorInfoContext()

	const [readyStates, setReadyStates] = useState<Record<string, Bounds | null>>(() => {
		const layers: Record<string, Bounds | null> = {}

		for (const part of parts) {
			layers[part.layerId] = null
		}

		return layers
	})

	const ready = !Object.values(readyStates).some((x) => x === null)

	const selectedCanvasSize = value.canvasSize as CanvasSizeId

	const layers: { layerId: string; value: ConfiguratorValueV1 }[] = useMemo(() => {
		const layers: Record<string, ConfiguratorValueV1['parts']> = {}

		for (const part of parts) {
			const layer = layers[part.layerId] ?? []

			layer.push(part)

			layers[part.layerId] = layer
		}

		return Object.entries(layers).map(([layerId, layer]) => ({
			layerId: layerId,
			value: { ...value, parts: layer },
		}))
	}, [parts, value])

	const readyHandler = useCallback(
		(layerId: string, bounds: Bounds) =>
			setReadyStates((old) => {
				if (old[layerId] !== bounds) {
					return { ...old, [layerId]: bounds }
				}
				return old
			}),
		[]
	)

	return (
		<div
			className={s.PreviewSvg}
			style={{
				transform: `scale(${info.canvasSizes[selectedCanvasSize].scale})`,
				opacity: ready ? 1 : 0,
			}}>
			<svg viewBox="0 0 800 600">
				<rect width={400} height={400} fill="pink" x="400" y="400" />
				{layers.map((layer) => (
					<ConfiguratorSvgPreviewLayer
						key={layer.layerId}
						layerId={layer.layerId}
						value={layer.value}
						onReady={readyHandler}
					/>
				))}
			</svg>
		</div>
	)
}

export function ConfiguratorPartEditor(props: {
	input: ConfiguratorPartEditability
	handler: ConfiguratorHandler
	preset: ConfiguratorPreset
	info: ConfiguratorInfoContextValue
}) {
	const {
		input,
		handler: [value, actions],
	} = props

	const { inputId, input: inputType } = input

	const getLightMaterialLabel = (value: string) => {
		return (
			(
				<span className={s.ColorLabel}>
					<span
						className={s.ColorLabelInner}
						style={{
							backgroundColor: props.info.lightMaterials[value].colorHex,
						}}></span>
				</span>
			) ?? value
		)
	}

	const setValue = useCallback(
		(_type: ConfiguratorInputType, id: InputId, value: string) => {
			actions.setInputValue(id, value)
		},
		[actions]
	)

	const changeHandler = useCallback<
		React.ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement>
	>(
		(e) => {
			const text = e.currentTarget.value
			setValue('text', inputId, text)
		},
		[inputId, setValue]
	)

	const inputValue = useMemo(() => {
		return value.inputValues[inputId]
	}, [inputId, value.inputValues])

	switch (inputType) {
		case 'text':
			return (
				<div className={s.TextWrapper}>
					<p>Text:</p>
					<textarea
						className={s.TextArea}
						onChange={changeHandler}
						value={inputValue}
						name={inputId}
					/>
				</div>
			)
		case 'icon':
			return (
				<div>
					<RadioPicker
						title="Ikonka:"
						value={inputValue}
						options={props.preset.icons}
						onChange={(_name, value) => setValue('icon', inputId, value)}
						name={inputId}
					/>
				</div>
			)
		case 'lightMaterial':
			return (
				<div>
					<RadioPicker
						title="Barva:"
						value={inputValue}
						options={props.preset.lightMaterials}
						onChange={(_name, value) => setValue('lightMaterial', inputId, value)}
						name={inputId}
						label={getLightMaterialLabel}
					/>
				</div>
			)
	}

	return null
}
