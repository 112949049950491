import { useCallback } from 'react'
import s from './RangeSlider.module.sass'

export function RangeSlider(props: {
	title?: React.ReactNode
	name: string
	value: number
	label?: string
	onChange: (value: string) => void
	min: number
	max: number
}) {
	const { onChange } = props
	const handleChange = useCallback<React.ChangeEventHandler<HTMLInputElement>>(
		(e) => {
			onChange(e.currentTarget.value)
		},
		[onChange]
	)

	return (
		<div className={s.RangeSlider}>
			{props.title && <p>{props.title}</p>}
			<div>
				<input
					type="range"
					min={props.min}
					max={props.max}
					value={props.value}
					className={s.Input}
					id=""
					onChange={handleChange}
				/>
			</div>
		</div>
	)
}
